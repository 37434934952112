import "./training-prices.scss";

import * as React from "react";
import { PropsWithChildren, useState } from "react";

import { Rate } from "../../../contexts/shared/domain/Prices";
import ModalForm from "../../shared/modalForm/ModalForm";
import TrainingPricesCard from "../../training/TrainingPricesCard/TrainingPricesCard";

interface PropsTrainingPrices extends PropsWithChildren<any> {
  rate: Rate;
}

const TrainingPrices: React.FC<PropsTrainingPrices> = (
  props: PropsTrainingPrices
) => {
  const { rate } = props;
  const [has_modal, setHasModal] = useState(false);
  const collectiveTraining_render = [];
  if (rate.collectiveTraining && rate.collectiveTraining.packs) {
    collectiveTraining_render.push(
      <TrainingPricesCard
        key={"trainersCard"}
        packs={rate.collectiveTraining.packs}
        color={rate.color}
      />
    );
  }
  const dualTraining_render = [];
  if (rate.dualTraining) {
    rate.dualTraining.bonos.forEach((bono, i) => {
      dualTraining_render.push(
        <div
          key={`dual-${i}`}
          className={
            "m-card m-card--no-shadow grid-70-30 grid-70-30--break-pro"
          }
          style={{
            backgroundColor: `${rate.color}`,
            color: "#fff",
          }}
        >
          <div className={"m-card__discount"}>
            <p className={"a-card__paragraph"}>{bono.sessions} sesiones</p>
          </div>
          <div className={"is-text-center"}>
            <p
              className={
                "a-card__paragraph a-card__paragraph-number is-fs-title-xl"
              }
            >
              {bono.price}
            </p>
            <p className={"a-card__paragraph"}>€ / sesión</p>
          </div>
        </div>
      );
    });
  }

  const singleTraining_render = [];
  if (rate.singleTraining) {
    rate.singleTraining.bonos?.forEach((bono, i) => {
      singleTraining_render.push(
        <div
          className={
            "m-card m-card--no-shadow grid-70-30 grid-70-30--break-pro"
          }
          style={{
            backgroundColor: `${rate.color}`,
            color: "#fff",
          }}
          key={`single-${i}`}
        >
          <div className={"m-card__discount"}>
            <p className={"a-card__paragraph"}>{bono.sessions} sesiones</p>
          </div>
          <div className={"is-text-center"}>
            <p
              className={
                "a-card__paragraph a-card__paragraph-number is-fs-title-xl"
              }
            >
              {bono.price}
            </p>
            <p className={"a-card__paragraph"}>€ / sesión</p>
          </div>
        </div>
      );
    });
  }
  const priceSession_render = [];
  if (rate.priceSession) {
    priceSession_render.push(
      <div
        key={"session"}
        className={
          "m-card m-card--grey-blue m-card--no-shadow grid-70-30 grid-70-30--break-pro"
        }
      >
        <div className={"m-card__discount"}>
          <p className={"a-card__paragraph"}>Sesión suelta</p>
        </div>
        <div className={"is-text-center"}>
          <p
            className={
              "a-card__paragraph a-card__paragraph-number is-fs-title-xl"
            }
          >
            {rate.priceSession}
          </p>
          <p className={"a-card__paragraph"}>€ / sesión</p>
        </div>
      </div>
    );
  }

  return (
    <>
      {has_modal && (
        <ModalForm title={"Comienza a entrenar"} is_active={setHasModal} />
      )}
      <div className={"t-training-prices"}>
        <div>
          {rate.collectiveTraining && (
            <div className={"rates__sections"}>
              <p className={"is-text-center h3"}>
                <span className={"is-grey"}>{rate.name}</span> Entrenamiento por
                grupos
              </p>
              {collectiveTraining_render}
            </div>
          )}
          {rate.dualTraining && (
            <div className={"rates__sections"}>
              <p className={"h3 is-text-center p-bottom"}>
                <span className={"is-grey"}>{rate.name}</span> Entrenamiento por
                parejas
              </p>
              <div
                className={
                  "container  grid-x2 grid-x2--break-device grid-x2--large-gap"
                }
              >
                {dualTraining_render}
              </div>
            </div>
          )}
          {rate.singleTraining && (
            <div className={"rates__sections"}>
              <p className={"h3 is-text-center p-bottom"}>
                <span className={"is-grey"}>{rate.name}</span> Entrenamiento
                individual
              </p>
              <div
                className={"grid-x2 grid-x2--break-desktop grid-x2--large-gap"}
              >
                {singleTraining_render}
              </div>
            </div>
          )}
          {rate.priceSession && { priceSession_render }}

          <div className={"m-cta"}>
            <h2 className={"a-cta__title"}>
              Tu mejor forma con Obelion Training
            </h2>
            <span
              onClick={() => {
                setHasModal(true);
              }}
              className={"a-button"}
              style={{
                backgroundColor: `${rate.color}`,
                color: "#fff",
              }}
            >
              Comienza a entrenar
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingPrices;
