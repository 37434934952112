import * as React from "react";
import { PropsWithChildren, useState } from "react";

import { Rate } from "../../contexts/shared/domain/Prices";
import HeroRates from "./hero/HeroRates";
import SelectedRate from "./SelectedRate/SelectedRate";

interface PropsRateHandler extends PropsWithChildren<any> {
  allRates: Rate[];
}

const RateView: React.FC<PropsRateHandler> = (props: PropsRateHandler) => {
  const rates: Rate[] = props.allRates;
  const [rate, setRate] = useState<Rate>(rates[0]);
  const rates_render = [];
  const ratesPhysiotherapy = rates.filter((rate) => rate.is_technique);
  const noRatesPhysiotherapy = rates.filter((rate) => !rate.is_technique);

  const [active, setActive] = useState(null);
  const activeLink = (i) => {
    if (active == i) {
      return setActive(null);
    }
    setActive(i);
  };

  rates_render.push(
    <span
      key={"-1"}
      onClick={() => {
        setRate(ratesPhysiotherapy[0]);
        activeLink(-1);
      }}
      className={`a-category-link ${
        active == -1 ? "a-category-link--active" : ""
      }`}
    >
      Fisioterapia
    </span>
  );
  noRatesPhysiotherapy.forEach((rate: Rate, i) => {
    rates_render.push(
      <span
        key={i}
        onClick={() => {
          setRate(rate);
          activeLink(i);
        }}
        className={`a-category-link ${
          active == i ? "a-category-link--active" : ""
        }`}
      >
        {rate.name}
      </span>
    );
  });
  return (
    <>
      <HeroRates>
        <p className={"a-offer"}>Seleccione un servicio:</p>
        {rates_render}
      </HeroRates>
      <SelectedRate rate={rate} rates={ratesPhysiotherapy} />
    </>
  );
};

export default RateView;
