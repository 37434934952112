import React, { useEffect, useLayoutEffect } from "react";

import Layout from "../views/shared/layout/layout";
import { graphql } from "gatsby";
import Reviews from "../components/GMB/Reviews";
import { SEO } from "../components/SEO/SEO";
import RateView from "../views/prices/RateView";

const IndexPage = ({ data }) => {
  const rates = data.allRates;

  useLayoutEffect(()=>{
    function setHeight(){
      const top = document.getElementsByClassName("o-hero-rates")[0].offsetTop;
      const height = document.getElementsByClassName("o-hero-rates")[0].offsetHeight;
      const scrollY = top + height;
      console.log("top", top);
      console.log("height", height);
      document.body.style.setProperty("--height-rates", `${scrollY}px`);
      window.addEventListener("resize", setHeight);
      return( ()=> {
        window.removeEventListener("resize", setHeight)
      } )
    }
    setHeight();
  }, []);

  return (
    <Layout>
      <SEO
        title={"Tarifas | Obelion"}
        description={"Tarifas"}
        canonical={"tarifas"}
      />
      <RateView allRates={rates}/>
      <Reviews />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
    query TarifasQuery{
        allRates {
           ...Rate
        }
        site {
            siteMetadata {
                projectId
                dataset
            }
        }
    }
`;