import "./selected-rate.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

import { Rate } from "../../../contexts/shared/domain/Prices";
import OtherServicePrices from "../OtherServicePrices/OtherServicePrices";
import TechniquePrices from "../TechniquePrices/TechniquePrices";
import TrainingPrices from "../TrainingPrices/TrainingPrices";

interface PropsSelectedRate extends PropsWithChildren<any> {
  rate: Rate;
  rates?: Rate[];
}

const SelectedRate: React.FC<PropsSelectedRate> = (
  props: PropsSelectedRate
) => {
  const { rate } = props;
  const ratesPhysiotherapy = props.rates;

  /*console.log("in selected", props);*/

  return (
    <div className={"container"}>
      {rate.is_technique && <TechniquePrices rates={ratesPhysiotherapy} />}
      {rate.is_training && <TrainingPrices rate={rate} />}
      {rate.is_otherService && <OtherServicePrices rate={rate} />}
    </div>
  );
};

export default SelectedRate;
