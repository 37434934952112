import "./hero-rates.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import background from "../../../assets/images/hero-tarifas.jpg";

const HeroRates: React.FC = (props: PropsWithChildren<any>) => {
  return (
    <>
      <section>
        <div className="hero-background">
          <img
            src={background}
            className={"img-rate"}
            width={600}
            height={300}
          />
        </div>
        <div className={"o-hero-rates"}>
          <div className={"m-hero-content"}>{props.children}</div>
        </div>
      </section>
    </>
  );
};

export default HeroRates;
