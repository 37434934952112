import "./other-service-prices.scss";

import * as React from "react";
import { PropsWithChildren, useState } from "react";

import { Rate } from "../../../contexts/shared/domain/Prices";
import ModalForm from "../../shared/modalForm/ModalForm";

interface PropsOtherServicePrices extends PropsWithChildren<any> {
  rate: Rate;
}

const OtherServicePrices: React.FC<PropsOtherServicePrices> = (
  props: PropsOtherServicePrices
) => {
  const { rate } = props;
  const [has_modal, setHasModal] = useState(false);

  const bonos_render = [];

  rate.bonos.forEach((bono, i) => {
    bonos_render.push(
      <div
        className={"m-card-content grid-70-30 grid-70-30--break-pro"}
        key={i}
      >
        <div className={"m-card__discount"}>
          <p className={"a-card__paragraph"}>{bono.sessions} sesiones</p>
        </div>
        <div className={"is-text-center"}>
          <p
            className={
              "a-card__paragraph a-card__paragraph-number is-fs-title-xl"
            }
          >
            {bono.price}
          </p>
          <p className={"a-card__paragraph"}>€ / sesión</p>
        </div>
      </div>
    );
  });

  return (
    <>
      {has_modal && (
        <ModalForm title={"Empieza tu nueva vida"} is_active={setHasModal} />
      )}
      <div className={"o-others-prices container rates__sections"}>
        <p className={"h3 is-text-center"}>
          <span className={"is-grey"}>Tarifas </span> {rate.name}
        </p>
        <div className={"grid-x2 grid-x2--break-device"}>
          <div className={"m-pack"}>
            <div
              className={
                "m-card m-card--no-shadow grid-70-30 grid-70-30--break-pro"
              }
              style={{
                backgroundColor: `${rate.color}`,
                color: "#fff",
              }}
            >
              <div className={"m-card__discount"}>
                <p className={"a-card__paragraph"}>Primera sesión</p>
              </div>
              <div className={"is-text-center"}>
                <p
                  className={
                    "a-card__paragraph a-card__paragraph-number is-fs-title-xl"
                  }
                >
                  {rate.priceSession}
                </p>
                <p className={"a-card__paragraph"}>€ / sesión</p>
              </div>
            </div>
          </div>
          <div className={"m-pack"}>
            <div
              className={
                "m-card m-card--no-shadow grid-70-30 grid-70-30--break-pro"
              }
              style={{
                backgroundColor: `${rate.color}`,
                color: "#fff",
              }}
            >
              <div className={"m-card__discount"}>
                <p className={"a-card__paragraph"}>Sesión de control</p>
              </div>
              <div className={"is-text-center"}>
                <p
                  className={
                    "a-card__paragraph a-card__paragraph-number is-fs-title-xl"
                  }
                >
                  {rate.priceSessionControl}
                </p>
                <p className={"a-card__paragraph"}>€ / sesión</p>
              </div>
            </div>
          </div>
        </div>
        <div className={"m-bonos"}>
          <p className={"h3 is-text-center"}>Nuestros bonos</p>
          <div
            className={"m-card m-card--no-shadow"}
            style={{
              backgroundColor: `${rate.color}`,
              color: "#fff",
            }}
          >
            {bonos_render}
          </div>
        </div>
        <div className={"m-cta"}>
          <h2 className={"a-cta__title"}>
            Más salud con Obelion
          </h2>
          <span
            onClick={() => {
              setHasModal(true);
            }}
            className={"a-button"}
            style={{
              backgroundColor: `${rate.color}`,
              color: "#fff",
            }}
          >
            Infórmate aquí
          </span>
        </div>
      </div>
    </>
  );
};

export default OtherServicePrices;
